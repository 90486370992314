import discoverVilgro from "../assets/discover.jpeg";

export const tabsData = [
  {
    id: "1",
    type: "Sponsorship",
    tag: "Submission Deadline",
    display_date: "19th July, 2024",
    format_date: "2024-07-19",
    display: {
      btn_bg: "#097748",
      text_color: "#fff",
      img: discoverVilgro,
      url: "https://villgroafrica.org/funding_calls/biosciences-studio/",
      disclaimer: `This event may be hosted by one of our partners. Please click the
                button above to check the requirements to qualify.`,
    },
  },

  {},
  {},
];
