import React from "react";
import styles from "./Highlight.module.scss";
import { IconType } from "react-icons";

interface Data {
  IconUrl: IconType;
  title: string;
  summary: string;
  iconType: number;
}
const Highlight: React.FC<Data> = ({ iconType, IconUrl, title, summary }) => {
  let iconStyles = { fill: "#f26739", fontSize: "3.5vw" };
  let iconStylesStroke = { stroke: "#f26739", fontSize: "3.5vw" };
  return (
    <div className={styles.highlight}>
      <div className={styles.icon}>
        <IconUrl style={iconType === 0 ? iconStyles : iconStylesStroke} />
      </div>
      <h3>{title}</h3>
      <p>{summary}</p>
    </div>
  );
};

export default Highlight;
