import React, { ReactNode } from "react";
import styles from "./Overlay.module.scss";

type OverlayProps = {
  children: ReactNode;
  onClick: () => void;
};

const Overlay: React.FC<OverlayProps> = ({ children, onClick }) => {
  return (
    <div onClick={onClick} className={styles.overlay}>
      {children}
    </div>
  );
};

export default Overlay;
