import React from "react";
import "./BackgroundImage.scss";

interface BackgroundImageProps {
  imageUrl: string;
  brightness: number;
  children: React.ReactNode;
}

const BackgroundImage: React.FC<BackgroundImageProps> = ({
  imageUrl,
  brightness,
  children,
}) => {
  return (
    <div
      className="background-container"
      style={{
        backgroundImage: `url(${imageUrl})`,
        filter: `brightness(${brightness})`,
      }}
    >
      <div className="content">{children}</div>
    </div>
  );
};

export default BackgroundImage;
