import React from "react";
import styles from "./DUDDiscoverTab.module.scss";

const DUDDiscoverTab: React.FC = () => {
  return (
    <div className={styles.discover_tab}>
      <div className={styles.tab_content}>
        <div className={styles.float}>
          <div className={styles.holder} />
        </div>

        <div className={styles.a}>
          <div className={styles.holder} />
        </div>
        <div className={styles.b}>
          <div className={styles.holder} />
        </div>
        <div className={styles.c}>
          <div className={styles.holder} />
        </div>
        <div className={styles.d}>
          <div className={styles.holder} />
        </div>
      </div>
    </div>
  );
};

export default DUDDiscoverTab;
