import React from "react";
import styles from "./Services.module.scss";
import Highlight from "../components/Highlight";
import ServiceTabs from "../components/ServiceTabs";
import { TabData } from "../types/types";

// react icons
import { PiDnaLight } from "react-icons/pi";
import { GiProcessor } from "react-icons/gi";
import { IoGitNetworkOutline } from "react-icons/io5";

// icons
import iconMicroscope from "../assets/icons/microscope.svg";
import iconPaw from "../assets/icons/paw.svg";
import iconHippo from "../assets/icons/hippo.svg";
import iconFactory from "../assets/icons/factory.svg";
import iconTractor from "../assets/icons/tractor.svg";
import iconTree from "../assets/icons/tree.svg";

// images
import techImage from "../assets/Technology.png";
import servicesImage from "../assets/services.png";

const data: TabData[] = [
  {
    title: "Human Health Genomics",
    summary:
      "Uncover the genetic underpinnings of diseases, identify drug targets, and personalize medicine and infectious organisms",
    description:
      "Human health genomics uses DNA analysis to understand the genetic basis of diseases, leading to personalized medicine and improved health outcomes.",
    imageUrl: servicesImage,
    iconUrl: iconMicroscope,
  },
  {
    title: "Veterinary Genomics",
    summary:
      "Unveiling the genetic makeup of animals for improved animal health and disease management.",
    description: "Decoding animal DNA for healthier pets and livestock",
    imageUrl: servicesImage,
    iconUrl: iconPaw,
  },
  {
    title: "Agriculture Genomics",
    summary:
      "Revolutionizing crops and livestock through targeted DNA analysis for increased yield, disease resistance, and sustainability.",
    description:
      "Improving crops and animals for a more productive and sustainable future.",
    imageUrl: servicesImage,
    iconUrl: iconTractor,
  },
  {
    title: "Environmental Genomics",
    summary:
      "Studying the composition and function of microbial communities in the environment to address pollution and ensure ecological health.",
    description: "Unraveling the secrets of microbiomes to clean our planet.",
    imageUrl: servicesImage,
    iconUrl: iconTree,
  },
  {
    title: "Industrial Biotechnology",
    summary:
      "Harnessing the power of DNA to develop innovative products and processes in various industries.",
    description: "Using DNA as a blueprint for innovative bio-products",
    imageUrl: servicesImage,
    iconUrl: iconFactory,
  },
  {
    title: "Wildlife Genomics",
    summary:
      "Conserving endangered species and understanding wildlife populations by analyzing their genetic makeup.",
    description: "Protecting endangered species through the power of DNA",
    imageUrl: servicesImage,
    iconUrl: iconHippo,
  },
];

const Services: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.float_ct}>
        <Highlight
          IconUrl={PiDnaLight}
          title={"Next Generation Sequencing"}
          summary="High throughput sequencing for comprehensive analysis."
          iconType={0}
        />
        <Highlight
          IconUrl={IoGitNetworkOutline}
          title={"Bioinformatics support"}
          summary="Converting raw sequence data into meaningful insights."
          iconType={1}
        />
        <Highlight
          IconUrl={GiProcessor}
          title={"Biological Software Solutions"}
          summary="Tailored software tools for efficient biological research."
          iconType={0}
        />
      </div>
      <div className={styles.tech_ct}>
        <img src={techImage} alt="tech" />
        <div className={styles.tech_cnt}>
          <h2>
            <span>_____</span>Technologies
          </h2>
          <h3>
            Our NGS and
            <br />
            Bioinformatics Solutions
          </h3>
          <p>
            {` We leverage cutting-edge Next Generation Sequencing (NGS) technology
            to provide comprehensive solutions across various fields`}
          </p>
        </div>
      </div>
      <div className={styles.services_ct}>
        <div className={styles.se_cnt}>
          <h2>
            <span>_____</span>Our Services<span>_____</span>
          </h2>
          <h3>
            Reliable & High-Quality <br />
            Services
          </h3>

          <ServiceTabs data={data} />
        </div>
      </div>
    </div>
  );
};

export default Services;
