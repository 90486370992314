import React, { useState } from "react";

import styles from "./ServiceTabs.module.scss";
import { TabData } from "../types/types";

interface TabsProps {
  data: TabData[];
}

const ServiceTabs: React.FC<TabsProps> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={styles.tabs_container}>
      <div className={styles.tabs_header}>
        {data.map((item, index) => (
          <div
            key={index}
            className={`${styles.tab} ${
              activeIndex === index ? styles.active : ""
            }`}
            onClick={() => setActiveIndex(index)}
          >
            <div className={styles.icon_circle}>
              <img src={item.iconUrl} alt="icon" />
            </div>
            <p>{item.title}</p>
            <div
              className={`${styles.arrow} 
                ${activeIndex === index ? styles.arrow_on : ""}`}
            />
          </div>
        ))}
      </div>
      <div className={styles.tabs_content}>
        {data.map(
          (item, index) =>
            activeIndex === index && (
              <div key={index} className={styles.content}>
                <div className={styles.details_cnt}>
                  <h4>{item.title}</h4>
                  <h5>{item.summary}</h5>
                  <p>{item.description}</p>
                </div>
                <img src={item.imageUrl} alt="service" />
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default ServiceTabs;
