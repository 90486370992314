import React, { useEffect, useRef, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { FaXmark } from "react-icons/fa6";

import "./App.scss";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Logo from "./assets/logo.png";
import Services from "./pages/Services";
import Discover from "./pages/Discover";
import useWindowSize from "./hooks/useWindowSize";
import MobServices from "./pages/mobile/MobServices";
import AboutMobile from "./pages/mobile/AboutMobile";
import ContactMobile from "./pages/mobile/ContactMobile";
import DiscoverMobile from "./pages/mobile/DiscoverMobile";

const App: React.FC = () => {
  const [activeSection, setActiveSection] = useState(
    localStorage.getItem("activeSection") || "home"
  );
  const [isHome, setIsHome] = useState(activeSection === "home");

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  let iconStyles = { fill: "#f26739", fontSize: "10vw" };

  // Ref to hold the latest value of `activeSection`
  const activeSectionRef = useRef(activeSection);

  const size = useWindowSize();
  const isMobile = size.width !== undefined && size.width <= 980; // just mobile = 768;

  useEffect(() => {
    // Update ref whenever `activeSection` changes
    activeSectionRef.current = activeSection;
  }, [activeSection]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".section");
      let currentSection = "home";
      const viewportHeight = window.innerHeight;
      const buffer = 100; // Buffer in pixels to avoid flickering

      sections.forEach((section) => {
        const sectionTop = section.getBoundingClientRect().top;
        const sectionBottom = section.getBoundingClientRect().bottom;
        const sectionId = section.getAttribute("id");

        // Check if the section is significantly visible in the viewport
        if (
          sectionTop < viewportHeight / 2 + buffer &&
          sectionBottom > viewportHeight / 2 - buffer
        ) {
          currentSection = sectionId ? sectionId : "home";
        }
      });

      // Check if the currentSection has changed before updating state
      if (currentSection !== activeSectionRef.current) {
        setActiveSection(currentSection);
        localStorage.setItem("activeSection", currentSection);
        setIsHome(currentSection === "home");
      }
    };

    // Use requestAnimationFrame for smoother updates
    let animationFrameId: number;
    const updateScroll = () => {
      handleScroll();
      animationFrameId = requestAnimationFrame(updateScroll);
    };

    animationFrameId = requestAnimationFrame(updateScroll);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    section?.scrollIntoView({ behavior: "smooth" });

    localStorage.setItem("activeSection", sectionId);
    setActiveSection(sectionId);
  };

  return (
    <div className="App">
      <nav className={`navbar ${isHome ? "transparent" : ""}`}>
        <div className="nav_content">
          <img src={Logo} alt="logo" onClick={() => scrollToSection("home")} />

          <ul>
            <li
              onClick={() => scrollToSection("home")}
              className={activeSection === "home" ? "active" : ""}
            >
              Home
            </li>
            <li
              onClick={() => scrollToSection("services")}
              className={activeSection === "services" ? "active" : ""}
            >
              Services
            </li>
            <li
              onClick={() => scrollToSection("about")}
              className={activeSection === "about" ? "active" : ""}
            >
              About Us
            </li>

            <li
              onClick={() => scrollToSection("contact")}
              className={activeSection === "contact" ? "active" : ""}
            >
              Contact Us
            </li>
          </ul>
          <div className="nav_btn" onClick={() => scrollToSection("discover")}>
            <p>Discover</p>
          </div>
          {isMobile && (
            <div className="menu_container">
              <div className="hamburger_ct" onClick={toggleMenu}>
                {isOpen ? (
                  <FaXmark style={iconStyles} />
                ) : (
                  <AiOutlineMenu style={iconStyles} />
                )}
              </div>
            </div>
          )}
        </div>
        {isOpen && isMobile && (
          <div className="menu_content">
            <ul>
              <li
                onClick={() => {
                  scrollToSection("home");
                  toggleMenu();
                }}
                className={activeSection === "home" ? "active" : ""}
              >
                Home
              </li>
              <li
                onClick={() => {
                  scrollToSection("services");
                  toggleMenu();
                }}
                className={activeSection === "services" ? "active" : ""}
              >
                Services
              </li>
              <li
                onClick={() => {
                  scrollToSection("about");
                  toggleMenu();
                }}
                className={activeSection === "about" ? "active" : ""}
              >
                About Us
              </li>

              <li
                onClick={() => {
                  scrollToSection("contact");
                  toggleMenu();
                }}
                className={activeSection === "contact" ? "active" : ""}
              >
                Contact Us
              </li>
              <li
                onClick={() => {
                  scrollToSection("discover");
                  toggleMenu();
                }}
                className={activeSection === "discover" ? "active" : ""}
              >
                Discover
              </li>
            </ul>
          </div>
        )}
      </nav>

      <section id="home" className="section">
        <Home
          activeSection={activeSection}
          onServices={() => scrollToSection("services")}
          onDiscover={() => scrollToSection("discover")}
          isMobile={isMobile}
        />
      </section>
      <section id="services" className="section">
        {isMobile ? <MobServices /> : <Services />}
      </section>
      <section id="about" className="section">
        {isMobile ? <AboutMobile /> : <About />}
      </section>

      <section id="contact" className="section">
        {isMobile ? <ContactMobile /> : <Contact />}
      </section>
      <section id="discover" className="section">
        {isMobile ? <DiscoverMobile /> : <Discover />}
      </section>
    </div>
  );
};

export default App;
