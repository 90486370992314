import React, { useState } from "react";
import styles from "./Discover.module.scss";
import DiscoverTab from "../components/DiscoverTab";
import { tabsData } from "../db/tabsData";
import Overlay from "../components/Overlay";
import DUDDiscoverTab from "../components/DUDDiscoverTab";

const Discover: React.FC = () => {
  const [activeTab, setActiveTab] = useState(tabsData[0].id);
  const [loadMore, setLoadMore] = useState(false);

  const selectedObject = tabsData.find((obj) => obj.id === activeTab);

  const handleClick = () => {
    window.open(selectedObject?.display?.url, "_blank");
  };
  return (
    <div className={styles.discover_container}>
      <h2>
        <span>_____</span> Discover <span>_____</span>
      </h2>
      <h3>Events & Sponsorships</h3>
      <div className={styles.discover}>
        <div className={styles.tabs_cnt}>
          {tabsData.slice(0, 3).map((tab, index) => {
            return (
              <>
                {tab?.id ? (
                  <DiscoverTab
                    key={index}
                    selected={activeTab === tab.id}
                    tab_type={tab.type}
                    tag={tab.tag}
                    display_date={tab.display_date}
                    format_date={tab.format_date}
                    onClick={() => setActiveTab(tab.id)}
                  />
                ) : (
                  <DUDDiscoverTab />
                )}
              </>
            );
          })}
          {loadMore ? (
            <Overlay onClick={() => setLoadMore(false)}>
              <div className={styles.overlay_cnt}>
                {tabsData.map((tab, index) => {
                  return (
                    <>
                      {tab?.id && (
                        <DiscoverTab
                          key={index}
                          selected={activeTab === tab.id}
                          tab_type={tab.type}
                          tag={tab.tag}
                          display_date={tab.display_date}
                          format_date={tab.format_date}
                          onClick={() => setActiveTab(tab.id)}
                        />
                      )}
                    </>
                  );
                })}
              </div>
            </Overlay>
          ) : tabsData.length <= 5 ? (
            <div className={styles.dud_load_btn}>
              <p>Load more events</p>
            </div>
          ) : (
            <div
              className={styles.load_btn}
              onClick={() => setLoadMore((item) => !item)}
            >
              <p>Load more events</p>
            </div>
          )}
        </div>
        <div className={styles.discover_content}>
          {selectedObject && (
            <div className={styles.image_cnt}>
              <img src={selectedObject.display?.img} alt="" />
            </div>
          )}
          {selectedObject && (
            <div className={styles.redirect_btn_ctn}>
              <div
                onClick={handleClick}
                className={styles.redirect_btn}
                style={{ backgroundColor: selectedObject.display?.btn_bg }}
              >
                <p style={{ color: selectedObject.display?.text_color }}>
                  Go to website
                </p>
              </div>
              <h4>Disclaimer</h4>
              <p>{selectedObject?.display?.disclaimer}</p>
            </div>
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <p>
          &copy; {new Date().getFullYear()}. The Africa Genomics Center &
          Consultancy.
        </p>
      </div>
    </div>
  );
};

export default Discover;
