import React from "react";

import styles from "./DiscoverTab.module.scss";
import useHasDatePassed from "../hooks/useHasDatePassed";

interface DiscoverTabContent {
  selected: boolean;
  tab_type: string;
  tag: string;
  display_date: string;
  format_date: string;
  onClick?: () => void;
}

const DiscoverTab: React.FC<DiscoverTabContent> = ({
  selected,
  tab_type,
  tag,
  display_date,
  format_date,
  onClick,
}) => {
  const passed = useHasDatePassed(format_date);
  return (
    <div className={styles.discover_tab}>
      <div
        className={`${styles.tab_content} ${
          selected ? (passed ? styles.selected : styles.active) : ""
        }`}
        onClick={onClick}
      >
        <div className={styles.float}>
          <p>{tab_type}</p>
        </div>
        <p className={tag}>{tag}</p>
        <h3>{display_date}</h3>
        <div
          className={`${styles.status}  ${
            selected ? "" : passed ? styles.status_invalid : styles.status_valid
          }`}
        >
          <h2>{passed ? "PASSED!" : "VALID"}</h2>
        </div>
        <p className={selected ? styles.notice_sel : styles.notice}>
          Check back regularly for more opportunities.
        </p>
      </div>
      {selected && (
        <div className={styles.circle_cnt}>
          <div className={styles.circle_a} />
          <div className={styles.circle_b} />
          <div className={styles.circle_c} />
        </div>
      )}
    </div>
  );
};

export default DiscoverTab;
