import React from "react";
import styles from "./AboutMobile.module.scss";

import missionImage from "../../assets/mission.png";
import visionImage from "../../assets/vision.png";
import teamImage from "../../assets/team.png";

const AboutMobile: React.FC = () => {
  return (
    <div className={styles.about_container}>
      <h2>
        <span>_____</span> About Us <span>_____</span>
      </h2>
      <h3>
        Your Trusted Partner
        <br />
        in Genomics
      </h3>

      <div className={styles.mission}>
        <div>
          <h4>Mission</h4>
          <p>
            To unlock the potential of DNA analysis for a healthier, more
            sustainable, and knowledge-driven Africa.
          </p>
        </div>
        <img src={missionImage} alt="mission" />
      </div>
      <div className={styles.vision}>
        <img src={visionImage} alt="vision" />
        <div>
          <h4>Vision</h4>
          <p>
            Our vision is to be a leading provider of genomics solutions that
            address critical development challenges in Africa and beyond.
          </p>
        </div>
      </div>
      <div className={styles.team}>
        <div>
          <h4>Team</h4>
          <p>
            We are passionate experts in genomics, biotechnology,
            bioinformatics, software development who are dedicated to unlocking
            the secrets in DNA.
          </p>
        </div>
        <img src={teamImage} alt="team" />
      </div>
      <div className={styles.reasons}>
        <h4>Why Choose Us</h4>
        <ul>
          <li>Cutting-edge technology and experienced personnel </li>
          <li>Commitment to quality and accuracy in our work</li>
          <li>Personalized approach and exceptional customer service</li>
          <li>
            Extensive experience working with diverse clients across various
            fields
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AboutMobile;
