import { useState, useEffect } from "react";

const useHasDatePassed = (targetDateString: string): boolean => {
  const [hasDatePassed, setHasDatePassed] = useState<boolean>(false);

  useEffect(() => {
    // Parse the target date string to a Date object
    const targetDate = new Date(targetDateString);

    // Get the current date
    const currentDate = new Date();

    // Compare the dates
    setHasDatePassed(currentDate > targetDate);
  }, [targetDateString]);

  return hasDatePassed;
};

export default useHasDatePassed;
