import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

import DeskbgImg from "../assets/desktopbg.png";
import MobbgImg from "../assets/mobilebg.png";
import labImg from "../assets/homelab.png";
import dnaImg from "../assets/dnablue.png";
import BackgroundImage from "../components/BackgroundImage";
import styles from "./Home.module.scss";

interface ChildProps {
  activeSection: string;
  isMobile: Boolean;
  onServices: () => void;
  onDiscover: () => void;
}

const Home: React.FC<ChildProps> = ({
  activeSection,
  onServices,
  onDiscover,
  isMobile,
}) => {
  const transition = { duration: 3, type: "spring" };

  const labControls = useAnimation();
  const dnaControls = useAnimation();

  useEffect(() => {
    if (activeSection === "home") {
      // Animate images into position when home section is active
      labControls.start({ x: 0, y: 0 });
      dnaControls.start({ x: 0, y: 0 });
    } else {
      // Move images out of view in opposite directions when leaving home section
      labControls.start({ x: -10, y: 10 });
      dnaControls.start({ x: 10, y: 10 });
    }
  }, [activeSection, labControls, dnaControls]);

  return (
    <BackgroundImage
      imageUrl={isMobile ? MobbgImg : DeskbgImg}
      brightness={0.84}
    >
      <div className={styles.container}>
        <div className={styles.content_ct}>
          <div className={styles.circle} />
          <div className={styles.inner_ct}>
            <h1>Unlocking the Secrets of DNA</h1>
            <div className={styles.btns_ct}>
              <div
                className={`${styles.btn} ${styles.dark}`}
                onClick={onServices}
              >
                <p>Services</p>
              </div>
              <div className={styles.btn} onClick={onDiscover}>
                <p>Discover</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.img_sct}>
          <motion.img
            initial={{ x: -10, y: 10 }}
            animate={labControls}
            transition={transition}
            src={labImg}
            alt="lab"
          />

          <div className={styles.img_float}>
            <motion.img
              transition={transition}
              initial={{ x: 10, y: 10 }}
              animate={dnaControls}
              src={dnaImg}
              alt="dna"
            />
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default Home;
