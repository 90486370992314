import React from "react";
import styles from "./ContactMobile.module.scss";
import contactImage from "../../assets/logobnw.png";
import { FaFacebookF, FaSquareXTwitter, FaLinkedinIn } from "react-icons/fa6";

const ContactMobile: React.FC = () => {
  let iconStyles = { fill: "#f26739", fontSize: "10vw" };
  return (
    <div className={styles.contact_container}>
      <div className={styles.contact_content}>
        <h2>
          <span>_____</span> Contact Us <span>_____</span>
        </h2>
        <h3>Get in Touch</h3>

        <div className={styles.address_cnt}>
          <p>Address:</p>
          <span>
            Westside Towers, Suite 503, Lower Kabete Road, Westlands, Nairobi,
            Kenya
          </span>

          <div className={styles.email_cnt}>
            <p>Email:</p>
            <span>info@tagccglobal.org</span>
          </div>
        </div>

        <div className={styles.social_media}>
          <div className={styles.media_links}>
            <a
              href="https://web.facebook.com/tagccglobal.org/?profile_tab_item_selected=about&_rdc=1&_rdr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF style={iconStyles} />
            </a>
            <a
              href="https://x.com/tagcc_info/status/1290516454270480386?t=m3CaIsPkmbU5E2LuHG2g7Q&s=08"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaSquareXTwitter style={iconStyles} />
            </a>
            <a
              href="https://www.linkedin.com/company/the-africa-genomics-centre-and-consultancy-tagcc-ltd?trk=blended-typeahead"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn style={iconStyles} />
            </a>
          </div>

          <img src={contactImage} alt="contact" />
        </div>
      </div>
    </div>
  );
};

export default ContactMobile;
