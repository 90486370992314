import React from "react";

import styles from "./ServiceList.module.scss";
import { TabData } from "../../types/types";

interface ServicesProps {
  data: TabData[];
}

const ServiceList: React.FC<ServicesProps> = ({ data }) => {
  return (
    <div className={styles.services_container}>
      {data.map((item, index) => (
        <div key={index} className={`${styles.service_ct} `}>
          <div className={styles.icon_circle}>
            <img src={item.iconUrl} alt="icon" />
          </div>
          <p>{item.title}</p>
          <div
            className={`${styles.content} 
              `}
          />
          <h4>{item.summary}</h4>
          <h5>{item.description}</h5>
        </div>
      ))}
    </div>
  );
};

export default ServiceList;
